export const HomepageSVG = () => {
    return (
        <svg width="453" height="384" viewBox="0 0 453 384" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M158.817 137.499H155.619V142.829H158.817V137.499Z" stroke="#ED1C24" stroke-width="0.75" stroke-miterlimit="10"/>
        <path d="M279.762 366.664C278.357 366.665 276.978 367.062 275.764 367.814C274.55 368.566 273.544 369.647 272.848 370.947L259.009 367.029L261.596 375.804H271.8C271.913 377.461 272.477 379.047 273.423 380.367C274.37 381.687 275.657 382.684 277.128 383.235C278.599 383.786 280.189 383.867 281.703 383.469C283.217 383.071 284.588 382.21 285.649 380.993C286.71 379.776 287.415 378.255 287.675 376.617C287.936 374.979 287.742 373.296 287.118 371.774C286.493 370.252 285.465 368.958 284.159 368.05C282.853 367.142 281.327 366.66 279.768 366.664H279.762Z" fill="#E9C46A"/>
        <path d="M153.284 366.664C154.74 366.665 156.17 367.062 157.429 367.814C158.689 368.566 159.732 369.647 160.453 370.947L174.805 367.029L172.123 375.804H161.54C161.424 377.461 160.839 379.047 159.857 380.367C158.876 381.687 157.54 382.684 156.015 383.235C154.489 383.786 152.84 383.867 151.271 383.469C149.701 383.071 148.278 382.21 147.178 380.993C146.078 379.776 145.347 378.255 145.077 376.617C144.806 374.979 145.007 373.296 145.655 371.774C146.303 370.252 147.37 368.958 148.724 368.05C150.078 367.142 151.66 366.66 153.277 366.664H153.284Z" fill="#E9C46A"/>
        <path d="M371.46 156.685C369.879 156.685 368.333 157.185 367.019 158.122C365.704 159.059 364.679 160.39 364.074 161.949C363.469 163.507 363.311 165.221 363.619 166.875C363.928 168.529 364.689 170.049 365.807 171.241C366.925 172.434 368.35 173.246 369.9 173.575C371.451 173.904 373.058 173.735 374.519 173.09C375.98 172.444 377.228 171.351 378.107 169.949C378.985 168.547 379.454 166.898 379.454 165.212C379.452 162.951 378.61 160.783 377.111 159.184C375.612 157.585 373.58 156.686 371.46 156.685ZM372.595 170.081C371.692 170.081 370.81 169.795 370.059 169.26C369.308 168.725 368.723 167.965 368.378 167.075C368.032 166.185 367.942 165.206 368.118 164.262C368.294 163.317 368.729 162.45 369.367 161.769C370.006 161.088 370.819 160.624 371.705 160.436C372.59 160.249 373.508 160.345 374.342 160.713C375.176 161.082 375.889 161.706 376.39 162.507C376.892 163.307 377.16 164.249 377.16 165.212C377.16 165.851 377.043 166.485 376.814 167.076C376.585 167.667 376.248 168.204 375.824 168.656C375.4 169.109 374.897 169.467 374.343 169.712C373.789 169.956 373.195 170.081 372.595 170.081Z" fill="#FFDDD2"/>
        <path d="M224.901 260.075H207.847V375.191H224.901V260.075Z" fill="#E9C46A"/>
        <path d="M280.473 246.219H152.275C150.59 246.219 149.224 247.552 149.224 249.197V260.295C149.224 261.94 150.59 263.273 152.275 263.273H280.473C282.158 263.273 283.525 261.94 283.525 260.295V249.197C283.525 247.552 282.158 246.219 280.473 246.219Z" fill="#E9C46A"/>
        <path d="M174.805 130.038C174.805 130.038 184.344 122.858 199.356 119.255C214.368 115.653 234.939 115.627 256.878 130.038H174.805Z" fill="#E76F51"/>
        <path d="M205.396 130.038C205.396 130.038 199.424 127.7 199.32 119.237C199.32 119.237 215.552 114.98 231.047 118.919C231.047 118.919 232.963 127.02 226.181 130.038H205.396Z" fill="#F9B499"/>
        <path d="M150.181 130.038H281.501C282.038 130.038 282.553 130.253 282.932 130.637C283.312 131.02 283.525 131.541 283.525 132.083V163.08H148.158V132.077C148.159 131.536 148.373 131.017 148.753 130.635C149.132 130.252 149.646 130.038 150.181 130.038Z" fill="#2F2E41"/>
        <path opacity="0.2" d="M150.181 130.038H281.501C282.038 130.038 282.553 130.253 282.932 130.637C283.312 131.02 283.525 131.541 283.525 132.083V163.08H148.158V132.077C148.159 131.536 148.373 131.017 148.753 130.635C149.132 130.252 149.646 130.038 150.181 130.038Z" fill="black"/>
        <path d="M224.901 103.391H205.715V120.445H224.901V103.391Z" fill="#F9B499"/>
        <path d="M156.685 218.506C166.103 218.506 173.739 205.621 173.739 189.727C173.739 173.833 166.103 160.948 156.685 160.948C147.266 160.948 139.631 173.833 139.631 189.727C139.631 205.621 147.266 218.506 156.685 218.506Z" fill="#E76F51"/>
        <path d="M274.465 218.506C284.178 218.506 292.052 205.621 292.052 189.727C292.052 173.833 284.178 160.948 274.465 160.948C264.752 160.948 256.878 173.833 256.878 189.727C256.878 205.621 264.752 218.506 274.465 218.506Z" fill="#E76F51"/>
        <path d="M216.374 34.1082C229.325 34.1082 239.824 26.4728 239.824 17.0541C239.824 7.63539 229.325 0 216.374 0C203.423 0 192.925 7.63539 192.925 17.0541C192.925 26.4728 203.423 34.1082 216.374 34.1082Z" fill="#54281C"/>
        <path opacity="0.2" d="M202.518 2.87797C202.518 2.87797 236.563 -0.668684 233.697 25.9635L235.604 26.6471C235.604 26.6471 245.098 18.451 235.604 7.48219C235.635 7.48219 223.49 -5.64691 202.518 2.87797Z" fill="#231F20"/>
        <path d="M453 211.045H18.12V214.242H453V211.045Z" fill="#E9C46A"/>
        <path d="M162.014 221.704C162.653 227.132 163.716 232.503 165.192 237.765C166.441 242.208 167.985 246.561 169.813 250.798C170.205 252.234 171.055 253.503 172.233 254.412C173.411 255.32 174.857 255.812 176.344 255.812H254.412C257.521 255.812 259.294 253.722 260.992 250.624C262.789 247.143 264.291 243.519 265.482 239.787C267.475 233.971 268.877 227.969 269.668 221.872L162.014 221.704Z" fill="#2F2E41"/>
        <path opacity="0.2" d="M185.503 255.786C185.503 255.786 174.185 242.971 177.568 231.296H191.837C198.755 231.296 205.489 234.163 209.858 239.54C212.967 243.345 215.148 248.665 213.87 255.812L185.503 255.786Z" fill="black"/>
        <path opacity="0.2" d="M245.357 255.786C245.357 255.786 256.354 242.971 253.067 231.296H239.209C232.487 231.296 225.945 234.163 221.7 239.54C218.673 243.345 216.56 248.665 217.802 255.812L245.357 255.786Z" fill="black"/>
        <path d="M176.936 364.419L186.553 253.85C186.84 250.559 188.367 247.501 190.825 245.293C193.283 243.086 196.488 241.893 199.793 241.958L202.088 242.003C204.075 242.041 206.034 242.485 207.843 243.308C209.652 244.131 211.274 245.316 212.608 246.789C213.942 248.262 214.96 249.992 215.599 251.873C216.239 253.754 216.487 255.746 216.327 257.726L207.678 365.598L176.936 364.419Z" fill="#2F2E41"/>
        <path d="M224.409 366.664L216.409 256.259C216.172 252.973 217.145 249.716 219.133 247.147C221.122 244.579 223.975 242.892 227.116 242.427L229.294 242.101C231.183 241.821 233.108 241.945 234.949 242.466C236.79 242.986 238.509 243.893 240.001 245.129C241.492 246.365 242.725 247.905 243.622 249.654C244.519 251.403 245.063 253.325 245.219 255.3L253.68 362.899L224.409 366.664Z" fill="#2F2E41"/>
        <path d="M176.782 356.005H208.001C209.101 356.005 210.19 356.229 211.205 356.664C212.221 357.099 213.144 357.736 213.922 358.54C214.699 359.344 215.316 360.298 215.737 361.348C216.158 362.398 216.374 363.524 216.374 364.661V383.718H168.409V364.661C168.409 363.524 168.626 362.398 169.047 361.348C169.468 360.298 170.084 359.344 170.862 358.54C171.639 357.736 172.562 357.099 173.578 356.664C174.594 356.229 175.683 356.005 176.782 356.005Z" fill="#7BC2BA"/>
        <path d="M224.747 356.005H255.966C257.066 356.005 258.154 356.229 259.17 356.664C260.186 357.099 261.109 357.736 261.887 358.54C262.664 359.344 263.281 360.298 263.702 361.348C264.122 362.398 264.339 363.524 264.339 364.661V383.718H216.374V364.661C216.374 363.524 216.591 362.398 217.011 361.348C217.432 360.298 218.049 359.344 218.826 358.54C219.604 357.736 220.527 357.099 221.543 356.664C222.559 356.229 223.647 356.005 224.747 356.005Z" fill="#7BC2BA"/>
        <path d="M183.988 368.832C186.38 367.549 184.915 359.937 180.715 351.832C176.514 343.726 171.169 338.196 168.777 339.479C166.384 340.763 167.849 348.374 172.05 356.48C176.25 364.585 181.595 370.116 183.988 368.832Z" fill="#7BC2BA"/>
        <path d="M182.778 366.499C184.157 365.759 182.861 360.505 179.886 354.762C176.91 349.019 173.38 344.963 172.002 345.703C170.624 346.442 171.919 351.697 174.895 357.439C177.87 363.182 181.4 367.238 182.778 366.499Z" fill="white"/>
        <path d="M212.036 356.265C215.765 347.924 216.792 340.239 214.33 339.099C211.868 337.96 206.849 343.797 203.12 352.138C199.391 360.479 198.363 368.164 200.826 369.304C203.288 370.443 208.307 364.605 212.036 356.265Z" fill="#7BC2BA"/>
        <path d="M209.249 357.389C211.891 351.48 212.883 346.158 211.465 345.501C210.047 344.845 206.755 349.103 204.113 355.012C201.471 360.922 200.479 366.244 201.897 366.901C203.315 367.557 206.607 363.299 209.249 357.389Z" fill="white"/>
        <path d="M365.199 221.704L351.741 383.718H354.909L379.454 221.704H365.199Z" fill="#E9C46A"/>
        <path opacity="0.2" d="M370.848 239.106L353.171 383.718H351.741L365.561 221.704H373.059L370.848 239.106Z" fill="black"/>
        <path d="M375.191 222.145L414.799 383.718L417.826 382.676L388.801 217.44L375.191 222.145Z" fill="#E9C46A"/>
        <path opacity="0.2" d="M385.63 237.18L415.694 383.26L414.373 383.718L375.191 221.981L382.092 219.572L385.63 237.18Z" fill="black"/>
        <path d="M65.1373 221.704L51.1623 383.718H54.4521L79.9412 221.704H65.1373Z" fill="#E9C46A"/>
        <path opacity="0.2" d="M70.2631 239.112L52.5916 383.718H51.1623L64.9782 221.704H72.48L70.2631 239.112Z" fill="black"/>
        <path d="M74.6118 222.145L114.227 383.718L117.247 382.67L88.2224 217.44L74.6118 222.145Z" fill="#E9C46A"/>
        <path opacity="0.2" d="M85.3257 237.179L116.181 383.254L114.832 383.718L74.6118 221.987L81.6952 219.572L85.3257 237.179Z" fill="black"/>
        <path d="M417.826 200.386H343.214V211.045H417.826V200.386Z" fill="#2A9D8F"/>
        <path opacity="0.2" d="M417.826 207.847H343.214V211.045H417.826V207.847Z" fill="black"/>
        <path d="M409.299 192.925H349.609V200.386H409.299V192.925Z" fill="#F07167"/>
        <path d="M417.826 188.661H357.071V192.925H417.826V188.661Z" fill="#2A9D8F"/>
        <path d="M416.76 183.332H354.939V188.661H416.76V183.332Z" fill="#3B8EA5"/>
        <path d="M399.706 149.224H373.059V183.332H399.706V149.224Z" fill="#FFDDD2"/>
        <path d="M453 214.242H18.12V222.769H453V214.242Z" fill="#E9C46A"/>
        <path d="M149.414 133.235H281.215C281.545 133.235 281.861 133.369 282.095 133.607C282.328 133.846 282.459 134.169 282.459 134.506V211.045H148.158V134.519C148.156 134.35 148.187 134.182 148.25 134.026C148.312 133.869 148.405 133.727 148.522 133.607C148.639 133.488 148.778 133.393 148.931 133.33C149.084 133.266 149.248 133.234 149.414 133.235Z" fill="#2F2E41"/>
        <path d="M260.306 356.268C264.036 347.927 265.063 340.242 262.6 339.102C260.138 337.963 255.119 343.801 251.39 352.141C247.661 360.482 246.634 368.167 249.096 369.307C251.558 370.446 256.577 364.609 260.306 356.268Z" fill="#7BC2BA"/>
        <path d="M257.519 357.393C260.161 351.483 261.153 346.161 259.735 345.504C258.317 344.848 255.025 349.106 252.383 355.016C249.741 360.925 248.749 366.247 250.167 366.904C251.586 367.56 254.877 363.302 257.519 357.393Z" fill="white"/>
        <path d="M232.249 368.828C234.642 367.545 233.176 359.933 228.976 351.828C224.776 343.722 219.431 338.192 217.038 339.475C214.645 340.759 216.111 348.37 220.311 356.476C224.511 364.581 229.856 370.112 232.249 368.828Z" fill="#7BC2BA"/>
        <path d="M231.039 366.495C232.418 365.755 231.123 360.501 228.147 354.758C225.171 349.015 221.641 344.959 220.263 345.699C218.885 346.438 220.18 351.693 223.156 357.435C226.132 363.178 229.661 367.234 231.039 366.495Z" fill="white"/>
        <path opacity="0.2" d="M373.059 149.224H377.621C377.621 149.224 376.678 160.123 379.445 167.7C380.157 169.596 381.41 171.224 383.039 172.369C384.668 173.514 386.597 174.122 388.569 174.112H399.706V183.332H373.059V149.224Z" fill="black"/>
        <path d="M22.3969 197.829C22.3969 197.829 18.9429 144.567 75.6127 157.703L109.466 176.471L111.096 198.956H119.216C120.955 198.956 122.623 199.665 123.853 200.927C125.083 202.189 125.774 203.9 125.774 205.685C125.774 207.469 125.083 209.18 123.853 210.442C122.623 211.704 120.955 212.413 119.216 212.413H17.0379C17.0379 212.413 7.9625 229.374 28.9053 230.803C28.9053 230.803 20.5232 241.056 29.8359 247.284C29.8359 247.284 -1.2066 247.765 0.036354 220.057C0.0426 220.044 1.9039 199.821 22.3969 197.829Z" fill="#2F2E41"/>
        <path d="M29.8447 247.285C33.3767 247.285 36.24 243.467 36.24 238.758C36.24 234.048 33.3767 230.231 29.8447 230.231C26.3127 230.231 23.4494 234.048 23.4494 238.758C23.4494 243.467 26.3127 247.285 29.8447 247.285Z" fill="#3F3D56"/>
        <path opacity="0.2" d="M29.8447 247.285C33.3767 247.285 36.24 243.467 36.24 238.758C36.24 234.048 33.3767 230.231 29.8447 230.231C26.3127 230.231 23.4494 234.048 23.4494 238.758C23.4494 243.467 26.3127 247.285 29.8447 247.285Z" fill="black"/>
        <path d="M103.924 199.32C118.935 199.32 131.104 188.344 131.104 174.805C131.104 161.265 118.935 150.289 103.924 150.289C88.9124 150.289 76.7435 161.265 76.7435 174.805C76.7435 188.344 88.9124 199.32 103.924 199.32Z" fill="#2F2E41"/>
        <path opacity="0.2" d="M103.924 199.32C118.935 199.32 131.104 188.344 131.104 174.805C131.104 161.265 118.935 150.289 103.924 150.289C88.9124 150.289 76.7435 161.265 76.7435 174.805C76.7435 188.344 88.9124 199.32 103.924 199.32Z" fill="black"/>
        <path d="M130.875 173.739L132.163 144.56C132.212 143.483 131.975 142.413 131.479 141.474C130.984 140.536 130.251 139.765 129.364 139.253C128.329 138.655 127.139 138.438 125.977 138.636C124.815 138.833 123.747 139.434 122.936 140.345L112.984 151.541C112.984 151.541 129.962 156.46 130.875 173.739Z" fill="#2F2E41"/>
        <path d="M119.379 154.425C119.379 154.425 122.599 148.683 124.759 148.172C124.947 148.137 125.142 148.167 125.311 148.255C125.481 148.344 125.616 148.486 125.695 148.66C126.225 149.73 127.29 152.966 126.631 160.948C126.631 160.936 122.722 155.889 119.379 154.425Z" fill="#383F42"/>
        <path d="M94.8635 151.745C94.8635 151.745 88.6347 144.163 83.9444 139.838C81.2847 137.376 76.8934 138.649 76.04 142.243C75.7857 143.389 75.6644 144.563 75.6788 145.74L76.9245 170.541C76.9245 170.541 78.9614 157.245 94.8635 151.745Z" fill="#2F2E41"/>
        <path d="M81.0071 160.948C81.0071 160.948 81.0844 151.5 81.9583 148.739C81.995 148.626 82.0555 148.521 82.1359 148.432C82.2164 148.344 82.3148 148.273 82.4246 148.226C82.5344 148.179 82.653 148.155 82.7725 148.158C82.8921 148.16 83.0097 148.188 83.1176 148.24C84.394 148.844 85.5296 149.71 86.4512 150.781C87.3728 151.852 88.0599 153.104 88.4682 154.457C88.4682 154.457 83.1235 157.628 81.0071 160.948Z" fill="#383F42"/>
        <path d="M94.354 180.134H93.2413C91.9759 180.132 90.7628 179.57 89.868 178.571C88.9732 177.572 88.4698 176.218 88.4682 174.805H89.3438C89.3454 175.959 89.7564 177.065 90.4868 177.882C91.2172 178.699 92.2076 179.16 93.2413 179.163H94.354C95.3888 179.161 96.3808 178.702 97.1125 177.885C97.8442 177.068 98.256 175.96 98.2576 174.805H99.1271C99.1255 176.218 98.6221 177.572 97.7273 178.571C96.8325 179.57 95.6194 180.132 94.354 180.134Z" fill="#83C5BE"/>
        <path d="M116.721 180.134H115.611C114.349 180.132 113.14 179.57 112.248 178.571C111.355 177.572 110.853 176.218 110.852 174.805H111.749C111.751 175.96 112.161 177.068 112.891 177.885C113.621 178.702 114.61 179.161 115.642 179.163H116.751C117.783 179.161 118.772 178.702 119.502 177.885C120.231 177.068 120.642 175.96 120.644 174.805H121.511C121.51 175.507 121.385 176.203 121.144 176.851C120.902 177.499 120.549 178.088 120.104 178.583C119.659 179.078 119.131 179.47 118.551 179.736C117.97 180.002 117.348 180.138 116.721 180.134Z" fill="#83C5BE"/>
        <path opacity="0.2" d="M104.989 185.464C107.05 185.464 108.72 184.748 108.72 183.865C108.72 182.982 107.05 182.266 104.989 182.266C102.929 182.266 101.259 182.982 101.259 183.865C101.259 184.748 102.929 185.464 104.989 185.464Z" fill="black"/>
        <path d="M191.859 377.322C193.625 377.322 195.056 376.607 195.056 375.724C195.056 374.841 193.625 374.125 191.859 374.125C190.093 374.125 188.661 374.841 188.661 375.724C188.661 376.607 190.093 377.322 191.859 377.322Z" fill="#2A9D8F"/>
        <path d="M239.824 377.322C241.59 377.322 243.021 376.607 243.021 375.724C243.021 374.841 241.59 374.125 239.824 374.125C238.057 374.125 236.626 374.841 236.626 375.724C236.626 376.607 238.057 377.322 239.824 377.322Z" fill="#2A9D8F"/>
        <path d="M205.715 108.72V105.522H222.769C222.769 105.522 221.731 108.467 205.715 108.72Z" fill="#F4A48E"/>
        <path d="M180.968 73.6487C180.968 73.6487 166.977 57.2014 181.154 38.74C186.592 31.7083 194.043 26.638 202.438 24.2556C212.069 21.5101 226.339 20.6634 243.22 29.7081C243.22 29.7081 265.776 41.896 256.969 76.7598C256.969 76.7598 251.022 100.776 239.791 112.984C239.791 112.984 252.597 73.9566 237.701 57.4644C237.701 57.4644 239.729 62.5962 239.22 64.8541C239.22 64.8541 230.848 48.8688 224.249 46.6429C224.249 46.6429 227.418 50.3121 227.927 53.5964C227.927 53.5964 216.429 40.6644 203.207 42.4092C203.207 42.4092 196.931 43.8076 190.475 51.3127C186.055 56.5821 183.048 62.9589 181.756 69.8063C181.532 70.916 181.272 72.1926 180.968 73.6487Z" fill="#54281C"/>
        <path d="M215.308 106.588C234.146 106.588 249.416 91.7947 249.416 73.5459C249.416 55.2971 234.146 40.5036 215.308 40.5036C196.471 40.5036 181.2 55.2971 181.2 73.5459C181.2 91.7947 196.471 106.588 215.308 106.588Z" fill="#F9B499"/>
        <path d="M180.667 81.007C183.316 81.007 185.464 77.4279 185.464 73.0129C185.464 68.5979 183.316 65.0188 180.667 65.0188C178.018 65.0188 175.871 68.5979 175.871 73.0129C175.871 77.4279 178.018 81.007 180.667 81.007Z" fill="#F9B499"/>
        <path opacity="0.2" d="M181.08 73.5459C181.08 73.5459 174.322 66.748 174.832 54.218C174.832 54.218 175.1 43.7879 186.191 34.1082L188.661 36.5549C188.661 36.5549 172.318 47.1679 181.08 73.5459Z" fill="#231F20"/>
        <path opacity="0.2" d="M239.11 64.7493C231.047 49.1468 224.135 46.6903 224.135 46.6903C225.784 48.7737 227.024 51.163 227.788 53.7271C218.961 44.1315 208.49 42.6985 208.49 42.6985C202.059 40.9329 196.392 45.5452 196.392 45.5452L189.292 37.5297L185.464 33.7362C185.464 33.7362 203.498 14.2314 235.325 26.3539C235.325 26.3539 253.923 31.1134 258.045 51.4945C258.045 51.4945 261.673 64.6918 254.855 84.3565C254.855 84.3565 248.099 105.013 239.779 112.984C239.779 112.984 245.478 96.2935 244.834 77.9722C244.834 77.9722 244.709 65.146 237.54 57.5526C237.54 57.5206 239.492 61.6084 239.11 64.7493Z" fill="#54281C"/>
        </svg>
    )
}
