export const CodeSVG = () => {
    return (
        <svg width="auto" height="50" viewBox="0 0 640 512" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M392.8 1.20005C375.8 -3.69995 358.1 6.20005 353.2 23.2001L225.2 471.2C220.3 488.2 230.2 505.9 247.2 510.8C264.2 515.7 281.9 505.8 286.8 488.8L414.8 40.8C419.7 23.8 409.8 6.10005 392.8 1.20005ZM473.4 121.3C460.9 133.8 460.9 154.1 473.4 166.6L562.7 256L473.3 345.4C460.8 357.9 460.8 378.2 473.3 390.7C485.8 403.2 506.1 403.2 518.6 390.7L630.6 278.7C643.1 266.2 643.1 245.9 630.6 233.4L518.6 121.4C506.1 108.9 485.8 108.9 473.3 121.4L473.4 121.3ZM166.7 121.3C154.2 108.8 133.9 108.8 121.4 121.3L9.3999 233.3C-3.1001 245.8 -3.1001 266.1 9.3999 278.6L121.4 390.6C133.9 403.1 154.2 403.1 166.7 390.6C179.2 378.1 179.2 357.8 166.7 345.3L77.2999 256L166.7 166.6C179.2 154.1 179.2 133.8 166.7 121.3Z" fill="#E76F51"/>
        </svg>
    )
}

export const DatabaseSVG = () => {
    return (
        <svg width="auto" height="50" viewBox="0 0 448 512" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M448 80V128C448 172.2 347.7 208 224 208C100.3 208 0 172.2 0 128V80C0 35.8 100.3 0 224 0C347.7 0 448 35.8 448 80ZM393.2 214.7C414 207.3 433.1 197.8 448 186.1V288C448 332.2 347.7 368 224 368C100.3 368 0 332.2 0 288V186.1C14.9 197.9 34 207.3 54.8 214.7C99.7 230.7 159.5 240 224 240C288.5 240 348.3 230.7 393.2 214.7ZM0 346.1C14.9 357.9 34 367.3 54.8 374.7C99.7 390.7 159.5 400 224 400C288.5 400 348.3 390.7 393.2 374.7C414 367.3 433.1 357.8 448 346.1V432C448 476.2 347.7 512 224 512C100.3 512 0 476.2 0 432V346.1Z" fill="#E76F51"/>
        </svg>
    )
}

export const ChartSVG = () => {
    return (
        <svg width="auto" height="50" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M64 64C64 46.3 49.7 32 32 32C14.3 32 0 46.3 0 64V400C0 444.2 35.8 480 80 480H480C497.7 480 512 465.7 512 448C512 430.3 497.7 416 480 416H80C71.2 416 64 408.8 64 400V64ZM470.6 150.6C483.1 138.1 483.1 117.8 470.6 105.3C458.1 92.8 437.8 92.8 425.3 105.3L320 210.7L262.6 153.3C250.1 140.8 229.8 140.8 217.3 153.3L105.3 265.3C92.8 277.8 92.8 298.1 105.3 310.6C117.8 323.1 138.1 323.1 150.6 310.6L240 221.3L297.4 278.7C309.9 291.2 330.2 291.2 342.7 278.7L470.7 150.7L470.6 150.6Z" fill="#E76F51"/>
        </svg>
    )
}

export const PenSVG = () => {
    return (
    <svg width="auto" height="50" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M362.7 19.3L314.3 67.7L444.3 197.7L492.7 149.3C517.7 124.3 517.7 83.8 492.7 58.8L453.3 19.3C428.3 -5.70001 387.8 -5.70001 362.8 19.3H362.7ZM291.7 90.3L58.5999 323.5C48.1999 333.9 40.5999 346.8 36.3999 360.9L0.999905 481.2C-1.50009 489.7 0.799905 498.8 6.99991 505C13.1999 511.2 22.2999 513.5 30.6999 511.1L151 475.7C165.1 471.5 178 463.9 188.4 453.5L421.7 220.3L291.7 90.3Z" fill="#E76F51"/>
    </svg>
    )
}

export const MagGlasSVG = () => {
    return (
    <svg width="auto" height="50" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M416 208C416 253.9 401.1 296.3 376 330.7L502.6 457.4C515.1 469.9 515.1 490.2 502.6 502.7C490.1 515.2 469.8 515.2 457.3 502.7L330.7 376C296.3 401.2 253.9 416 208 416C93.1 416 0 322.9 0 208C0 93.1 93.1 0 208 0C322.9 0 416 93.1 416 208ZM208 352C226.91 352 245.636 348.275 263.106 341.039C280.577 333.802 296.452 323.195 309.823 309.823C323.195 296.452 333.802 280.577 341.039 263.106C348.275 245.636 352 226.91 352 208C352 189.09 348.275 170.364 341.039 152.894C333.802 135.423 323.195 119.548 309.823 106.177C296.452 92.805 280.577 82.198 263.106 74.9613C245.636 67.7247 226.91 64 208 64C189.09 64 170.364 67.7247 152.894 74.9613C135.423 82.198 119.548 92.805 106.177 106.177C92.805 119.548 82.198 135.423 74.9613 152.894C67.7247 170.364 64 189.09 64 208C64 226.91 67.7247 245.636 74.9613 263.106C82.198 280.577 92.805 296.452 106.177 309.823C119.548 323.195 135.423 333.802 152.894 341.039C170.364 348.275 189.09 352 208 352Z" fill="#E76F51"/>
    </svg>
    )
}

export const ListSVG = () => {
    return (
        <svg width="auto" height="50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M40 48C26.7 48 16 58.7 16 72v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V72c0-13.3-10.7-24-24-24H40zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM16 232v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V232c0-13.3-10.7-24-24-24H40c-13.3 0-24 10.7-24 24zM40 368c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V392c0-13.3-10.7-24-24-24H40z" fill="#E76F51"/>
        </svg>
    )
}